@media screen and (max-width: 400px) {

  .intro h1 {
    font-size: 70px;
  }

  #menu a.navbar-brand {
    font-size: 20px;
  }

  .map-img {
    width: 350px;
  }

  #features {
    padding: 20px;
    box-sizing: border-box;
  }

  #header,
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    box-sizing: border-box;
  }

  body,
  html {
    max-width: 100%;
  }
}